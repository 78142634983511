import React from "react";
import "./footer.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

//Code for footer, includes hyperlinks to different parts of the website

const Footer = () => (
  <nav>
    <div class="row" className="footer">
      <ul>
        <li className="active">
          <Link to="/">
            URBANO DESIGN <span class="y-link">AND</span> DEVELOPMENT{" "}
            <span class="y-link">LTD</span>{" "}
          </Link>
        </li>
        <li className="text">
          <Link to="/commercial">Commercial</Link>
        </li>
        <li className="text">
          <Link to="/residential">Residential</Link>
        </li>
        <li className="text">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="right">
          <Link to="/">| Dana A. Peers</Link>
        </li>
      </ul>
    </div>
  </nav>
);

export default Footer;
