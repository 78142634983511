import React from "react";
import Carousel from "./ResidentialCarousel";

const Residential = ({ images }) => {
  return (
    <div className="carouselStyling">
      <section className="section" aria-labelledby="Commercial">
        <div className="carousel-container">
          <Carousel images={images} isRTL={true} thumbnailHeight="200px" />
        </div>
      </section>
    </div>
  );
};

export default Residential;
