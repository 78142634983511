import React from "react";
import Carousel from "./CommercialCarousel";
import "../carousel.css";

const Commercial = ({ images }) => {
  return (
    <div className="carouselStyling">
      <section className="section" aria-labelledby="Commercial">
        <div className="carousel-container">
          <Carousel images={images} isRTL={true} />
        </div>
      </section>
    </div>
  );
};

export default Commercial;
