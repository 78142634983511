import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const ResidentialCarousel = () => {
  //const images = [0, 1, 2].map((number) => ({
  //src: "/assets/CommercialPictures/commercial-development-" + number + ".jpg"", // required
  //}));

  //const images = [9, 8, 7, 6, 5].map((number) => ({
  // src: `https://placedog.net/${number}00/${number}00?id=${number}`,
  //}));
  const images = new Array();

  images[0] = new Image();
  images[0].src =
    "/assets/ResidentialPictures/1-95D_KUIPERS_EXT_170504 (1)-min.jpg";

  images[1] = new Image();
  images[1].src =
    "/assets/ResidentialPictures/1-COPITHORNE_EXT_01_180125-min.jpg";

  images[2] = new Image();
  images[2].src = "/assets/ResidentialPictures/bc-recreactional-property-0.jpg";

  images[3] = new Image();
  images[3].src = "/assets/ResidentialPictures/bc-recreactional-property-1.jpg";

  images[4] = new Image();
  images[4].src = "/assets/ResidentialPictures/bc-recreactional-property-2.jpg";

  images[5] = new Image();
  images[5].src = "/assets/ResidentialPictures/bc-recreactional-property-3.jpg";

  images[6] = new Image();
  images[6].src = "/assets/ResidentialPictures/bc-recreactional-property-4.jpg";

  images[7] = new Image();
  images[7].src = "/assets/ResidentialPictures/calgary-area-home-0.jpg";

  images[8] = new Image();
  images[8].src = "/assets/ResidentialPictures/calgary-area-home-1.jpg";

  images[9] = new Image();
  images[9].src = "/assets/ResidentialPictures/calgary-area-home-2.jpg";

  images[10] = new Image();
  images[10].src = "/assets/ResidentialPictures/calgary-area-home-3.jpg";

  images[11] = new Image();
  images[11].src = "/assets/ResidentialPictures/calgary-area-home-4.jpg";

  images[12] = new Image();
  images[12].src = "/assets/ResidentialPictures/calgary-area-home-5.jpg";

  images[13] = new Image();
  images[13].src = "/assets/ResidentialPictures/calgary-area-home-6.jpg";

  images[14] = new Image();
  images[14].src = "/assets/ResidentialPictures/calgary-area-home-7.jpg";

  images[15] = new Image();
  images[15].src = "/assets/ResidentialPictures/canmore-residence-0.jpg";

  images[16] = new Image();
  images[16].src = "/assets/ResidentialPictures/country-hills-residence-0.jpg";

  images[17] = new Image();
  images[17].src = "/assets/ResidentialPictures/elbow-valley-residence-0.jpg";

  images[18] = new Image();
  images[18].src = "/assets/ResidentialPictures/elbow-valley-residence-1.jpg";

  images[19] = new Image();
  images[19].src = "/assets/ResidentialPictures/elbow-valley-residence-2.jpg";

  images[20] = new Image();
  images[20].src = "/assets/ResidentialPictures/elbow-valley-residence-3.jpg";

  images[21] = new Image();
  images[21].src = "/assets/ResidentialPictures/mexico-residential-0.jpg";

  images[22] = new Image();
  images[22].src = "/assets/ResidentialPictures/mexico-residential-1.jpg";

  images[23] = new Image();
  images[23].src = "/assets/ResidentialPictures/mexico-residential-2.jpg";

  images[24] = new Image();
  images[24].src =
    "/assets/ResidentialPictures/multi-family-residence-0-clr-sketch.jpg";

  images[25] = new Image();
  images[25].src = "/assets/ResidentialPictures/multi-family-residence-1.jpg";

  images[26] = new Image();
  images[26].src = "/assets/ResidentialPictures/rural-renovation-0.jpg";

  images[27] = new Image();
  images[27].src = "/assets/ResidentialPictures/sketch-bw-residential-0.jpg";

  images[28] = new Image();
  images[28].src = "/assets/ResidentialPictures/sketch-bw-residential-1.jpg";

  images[29] = new Image();
  images[29].src = "/assets/ResidentialPictures/sketch-bw-residential-2.jpg";

  images[30] = new Image();
  images[30].src = "/assets/ResidentialPictures/sketch-bw-residential-3.jpg";

  images[31] = new Image();
  images[31].src = "/assets/ResidentialPictures/sketch-bw-residential-4.jpg";

  images[32] = new Image();
  images[32].src = "/assets/ResidentialPictures/sketch-bw-residential-5.jpg";

  images[33] = new Image();
  images[33].src = "/assets/ResidentialPictures/sketch-bw-residential-6.jpg";

  images[34] = new Image();
  images[34].src = "/assets/ResidentialPictures/sketch-bw-residential-7.jpg";

  images[35] = new Image();
  images[35].src = "/assets/ResidentialPictures/sketch-bw-residential-8.jpg";

  images[36] = new Image();
  images[36].src =
    "/assets/ResidentialPictures/sketch-clr-calgary-inner-city.jpg";

  images[37] = new Image();
  images[37].src =
    "/assets/ResidentialPictures/sketch-clr-multi-family-home.jpg";

  images[38] = new Image();
  images[38].src = "/assets/ResidentialPictures/sketch-clr-residential-0.jpg";

  images[39] = new Image();
  images[39].src = "/assets/ResidentialPictures/sketch-clr-residential-1.jpg";

  return (
    <Carousel
      images={images}
      style={{
        height: "70vh",
        width: "80vw",
        display: "block",
        margin: "auto",
      }}
    />
  );
};

export default ResidentialCarousel;
