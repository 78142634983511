import React from "react";
import "../contact.css";

function Contact() {
  return (
    <div className="main">
      <div>
        <h2> Contact</h2>
        <div className="contactform">
          <p>URBANO DESIGN AND DEVELOPMENT LTD.</p>
          <p> dana@urbanodesignanddevelopment.com</p>
          <p> (403) 861 - 1415</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
