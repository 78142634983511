import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const CommercialCarousel = () => {
  //const images = [0, 1, 2].map((number) => ({
  //src: "/assets/CommercialPictures/commercial-development-" + number + ".jpg"", // required
  //}));

  //const images = [9, 8, 7, 6, 5].map((number) => ({
  // src: `https://placedog.net/${number}00/${number}00?id=${number}`,
  //}));
  const images = new Array();

  images[0] = new Image();
  images[0].src = "/assets/CommercialPictures/commercial-development-0.jpg";

  images[1] = new Image();
  images[1].src = "/assets/CommercialPictures/commercial-development-1.jpg";

  images[2] = new Image();
  images[2].src = "/assets/CommercialPictures/commercial-development-2.jpg";

  images[3] = new Image();
  images[3].src = "/assets/CommercialPictures/commercial-development-2-1.jpg";

  images[4] = new Image();
  images[4].src =
    "/assets/CommercialPictures/leduc-commercial-industial-0-clr-sketch.jpg";

  images[5] = new Image();
  images[5].src = "/assets/CommercialPictures/leduc-commercial-industial-1.jpg";

  images[6] = new Image();
  images[6].src =
    "/assets/CommercialPictures/sketch-clr-commercial-development-1.jpg";

  images[7] = new Image();
  images[7].src =
    "/assets/CommercialPictures/sketch-clr-commercial-development-2.jpg";

  //images[8] = new Image();
  //images[8].src =
  // "/assets/CommercialPictures/sketch-clr-commercial-development.jpg";
  // const images = [ "'src': 'assets\CommercialPictures\commercial-development-0.jpg'"]

  return (
    <Carousel
      images={images}
      style={{
        height: "70vh",
        width: "80vw",
        display: "block",
        margin: "auto",
      }}
    />
  );
};

export default CommercialCarousel;
